<template>
  <v-main>
    <header class="tw-bg-white tw-py-12 tw-px-4">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <h1>{{ $t('global.titles.added') }}</h1>
      </div>
    </header>

    <!-- <main v-if="user && user.emailVerified === true" class="tw-mt-8 tw-px-4 tw-mb-24"> -->
    <main v-if="user" class="tw-mt-8 tw-px-4 tw-mb-24">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <section class="">
          <v-tabs
            show-arrows
            icons-and-text
            v-model="tab"
            background-color="transparent"
            class="tw-border-solid tw-border-0 tw-border-b tw-border-gray-500"
          >
            <v-tab
              class="tw-font-bold tw-tracking-normal tw-normal-case tw-text-gray-800"
              active-class="tw-text-koderia"
            >
              {{ $t('profile.posts') }}
            </v-tab>
            <v-tabs-slider class="tw-text-koderia"></v-tabs-slider>
          </v-tabs>
          <v-tabs-items v-model="tab" class="tw-bg-transparent tw-mt-8">
            <v-tab-item>
              <ka-user-added-tab-posts />
            </v-tab-item>
          </v-tabs-items>
        </section>
      </div>
    </main>

    <!-- <main v-else class="tw-mt-8 tw-px-4 tw-mb-24">
        <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
            <ka-user-verification-tab />
        </div>
    </main> -->
  </v-main>
</template>

<script>
import KaUserAddedTabPosts from './ka-user-added-tab-posts'
// import KaUserVerificationTab from "./ka-user-verification-tab";

export default {
  components: {
    KaUserAddedTabPosts
    // KaUserVerificationTab
  },
  created() {
    this.$store.dispatch('APPBAR_NON_TRANSPARENTABLE')
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    user() {
      return this.$store.state.AUTH.user
    },
    formState: function () {
      return this.$store.state.USER.formState
    },
    formStateMessage: function () {
      return this.$store.state.USER.formStateMessage
    }
  },
  watch: {
    formState: {
      deep: true,
      handler(state) {
        Object.keys(state).forEach(name => {
          if (state[name] === 'SUCCESS') {
            setTimeout(() => {
              this.$store.commit('USER/SET_FORM_STATE', { name, state: 'IDLE' })
            }, 2500)
          }
        })
      }
    }
  },
  methods: {
    isState(name, state) {
      return this.formState[name] === state
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-tabs-bar {
  margin: 0 0.75rem;
  z-index: 1;
}

::v-deep .v-tabs-slider-wrapper {
  height: 0.25rem !important;
}
</style>
