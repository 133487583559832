<template>
  <div>
    <section
      v-if="posts && posts.length > 0"
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6"
    >
      <post-preview :post="post" v-for="post in posts" :key="post.id" />
    </section>

    <section v-else-if="loading" class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
      <post-preview-skeleton v-for="idx in 6" :key="idx" />
    </section>

    <section v-else class="tw-flex tw-flex-col tw-items-center tw-bg-white tw-rounded-sm tw-px-4 tw-py-12">
      <img src="./assets/saved-posts-empty.svg" alt="Empty" class="tw-w-48 tw-h-48" />

      <p class="tw-italic tw-text-gray-800">
        {{ $t('profile.descriptions.no_posts') }}
      </p>

      <a
        href="#"
        class="tw-italic tw-text-koderia tw-mt-2 tw-inline-block tw-no-underline hover:tw-underline"
        @click.prevent="addPostDialog = true"
      >
        {{ $t('global.actions.add_post') }}
      </a>
    </section>
    <ka-user-add-post-dialog v-model="addPostDialog" />
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

import { postConverter } from '../services'
import PostPreview from '@web/components/post-preview'
import PostPreviewSkeleton from '@web/components/post-preview-skeleton'
import KaUserAddPostDialog from '@web/user/ka-user-add-post-dialog'

export default {
  components: {
    PostPreview,
    PostPreviewSkeleton,
    KaUserAddPostDialog
  },
  data() {
    return {
      posts: [],
      loading: true,
      addPostDialog: false
    }
  },
  created() {
    this.fetchAddedPosts()
  },
  computed: {
    user() {
      return this.$store.state.AUTH.user
    }
  },
  methods: {
    fetchAddedPosts() {
      this.loading = true
      firebase
        .firestore()
        .collection('links')
        .where('status', '==', 'PUBLISHED')
        .where('userId', '==', this.user.uid)
        .withConverter(postConverter)
        .get()
        .then(dataSnapshot => {
          const posts = []
          dataSnapshot.forEach(postDoc => {
            posts.push(postDoc.data())
          })
          this.posts = posts
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
